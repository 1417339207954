import MCNavigationComponent from '../../components/form/mc/mcNavigation';
import React from 'react';

class IndexPage extends React.Component {
  render() {
    return <MCNavigationComponent />;
  }
}

export default IndexPage;
